import { Button, ThemeProvider } from '@mui/material';
import ProposifyTheme from 'muiTheme/PyTheme';
import { ReactNode } from 'react';

interface Props {
  buttonText: string;
  buttonIcon?: ReactNode;
  onClick?: () => void;
}

export function PrimaryActionButton({ buttonText, buttonIcon, onClick }: Props) {
  return (
    <ThemeProvider theme={ProposifyTheme}>
      <Button
        startIcon={buttonIcon}
        data-testid="empty-state-primary-button"
        color="primary"
        variant="contained"
        size="medium"
        sx={{
          textTransform: 'none',
          borderRadius: '100px',
          fontWeight: '600',
          letterSpacing: '0.55px',
        }}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </ThemeProvider>
  );
}
