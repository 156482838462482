import { ContentType, getContentType } from 'utils/getContentType';
import { TemplateEditorMenu } from '../TemplateEditorMenu/TemplateEditorMenu';
import { EditorMenu as DocumentEditorMenu } from '../EditorMenu/EditorMenu';
import { EditorMenuWithoutVariable } from '../EditorMenu/EditorMenuWithoutVariable';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { FeatureFlags } from 'utils/featureFlags';
interface Props {
  documentId: string;
  assetId: string;
}

export function RenderEditorMenu({ documentId, assetId }: Props) {
  const contentType = getContentType();
  const evaluatedFeatureFlags = useFeatureFlag([FeatureFlags.spVariables]);
  const isVariablesAvailable = evaluatedFeatureFlags[FeatureFlags.spVariables];

  if (contentType === ContentType.DOCUMENT) {
    return isVariablesAvailable ? <DocumentEditorMenu documentId={documentId} /> : <EditorMenuWithoutVariable documentId={documentId} />;
  } else {
    return <TemplateEditorMenu assetId={assetId} />;
  }
}
