import { Box, ThemeProvider, Typography } from '@mui/material';
import ProposifyTheme from 'muiTheme/PyTheme';
import { ReactNode } from 'react';
import { PrimaryActionButton } from './components/primaryActionButton';

interface Props {
  primaryGraphic?: string;
  headerText: string;
  supportingText?: string;
  primaryActionButton?: ReactNode;
}

const EmptyState = ({ primaryGraphic, headerText, supportingText, primaryActionButton }: Props) => {
  return (
    <ThemeProvider theme={ProposifyTheme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '24px',
          background: '#fff',
          borderRadius: '28px',
          height: '100%',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
          {primaryGraphic && (
            <img src={primaryGraphic} data-testid="empty-state-graphic" width={'180px'} height={'118px'} alt="Empty State Graphic" />
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
            <Typography variant="h6" data-testid="empty-state-header">
              {headerText}
            </Typography>
            {supportingText && (
              <Typography variant="body1" data-testid="empty-state-text" sx={{ color: 'primary.light' }}>
                {supportingText}
              </Typography>
            )}
          </Box>
        </Box>
        {primaryActionButton && <Box>{primaryActionButton}</Box>}
      </Box>
    </ThemeProvider>
  );
};

EmptyState.PrimaryActionButton = PrimaryActionButton;

export default EmptyState;
