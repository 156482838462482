import React, { useState, useRef, KeyboardEvent, useEffect } from 'react';
import { Col, Layout, Row } from 'antd';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';

import { ProfileActions, HelpButton, Paragraph, Button, Icon, Tooltip } from 'components';
import PriceInput from 'components/priceInput';
import { Endpoints } from 'utils/endpoints';
import useUpdateDocumentMutation from 'hooks/useUpdateDocumentMutation';
import { DocumentShareModal } from 'components/editor/document-share-modal';
import { Header } from 'components/editor/document-share-modal/header';
import { DocumentShare } from 'components/editor/document-share';
import { DocumentStatus, UpdateDocumentPayload } from 'services/repositories/interfaces/DocumentRepository';
import { useGetDocumentByIdQuery } from 'hooks/useGetDocumentByIdQuery';
import './styles.less';
import SaveStatusText from '../shared/components/SaveStatus/SaveStatus';
import { EditorToolbar } from './EditorToolbar';
import { updateVariableValue } from '../grid/reduxStore/allVariableSlice';
import { useAppDispatch } from '../grid/reduxStore/Store';

interface EditorMenuProps {
  documentId: string;
}

export const sanitizeTitle = (value: string) => {
  return DOMPurify.sanitize(value).substring(0, 99);
};

const blurOnEnter = (event: KeyboardEvent<HTMLInputElement>) => {
  if (event.key === 'Enter') {
    event.currentTarget.blur();
  }
};

export function EditorMenuWithoutVariable({ documentId }: EditorMenuProps) {
  const [title, setTitle] = useState<string>('Document Title');
  const [documentPrice, setDocumentPrice] = useState<number | null>(0);
  const previewURL = Endpoints.getDocumentPreviewPage(documentId);
  const handlePreviewRedirect = () => window.open(previewURL, '_blank');
  const documentTitleRef = useRef<HTMLInputElement>() as React.MutableRefObject<HTMLInputElement>;
  const { mutate } = useUpdateDocumentMutation();
  const { t } = useTranslation();
  const { data: documentData, isLoading } = useGetDocumentByIdQuery(documentId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (documentData?.title) {
      setTitle(documentData.title);
    }
    if (documentData?.price) {
      setDocumentPrice(documentData.price);
    }
  }, [documentData]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = sanitizeTitle(event.target.value);
    setTitle(sanitizedValue);
  }

  const updateDocumentProperties = (payload: Omit<UpdateDocumentPayload, 'documentId'>, callback: () => void) => {
    mutate({ documentId, ...payload }, { onSuccess: callback });
  };

  const updateTitle = () => {
    let sanitizedTitle = sanitizeTitle(title);
    if (!sanitizedTitle.trim()) {
      const defaultTitle = t('editor.default_document_title');
      setTitle(defaultTitle);
      sanitizedTitle = defaultTitle;
    }
    persistTitle({ title: sanitizedTitle });
  };

  const persistTitle = (titlePayload: { title: string }) => {
    updateDocumentProperties(titlePayload, () =>
      dispatch(updateVariableValue({ type: 'proposal', variableName: 'proposal_name', newValue: titlePayload.title }))
    );
  };

  const updatePrice = () => {
    updateDocumentProperties({ price: documentPrice }, () =>
      dispatch(updateVariableValue({ type: 'proposal', variableName: 'total', newValue: String(documentPrice) }))
    );
  };

  const [isVisible, setIsVisible] = useState(false);

  const closeShareModal = () => setIsVisible(false);
  const openShareModal = () => setIsVisible(true);

  const documentTitleWithCompanyName = [title, documentData?.company?.name].filter(Boolean).join(' - ');

  return (
    <Layout.Header className="editor-header">
      <Row justify="space-between" align="top" className="editor-menu-container">
        <Col className="editor-menu-container__column--left document-details">
          {isLoading ? (
            <Skeleton variant="rounded" width="50vw" data-testid="skeleton-title" />
          ) : (
            <Tooltip title={title} placement="bottom">
              <input
                ref={documentTitleRef}
                data-testid="document-title"
                onKeyDown={blurOnEnter}
                className="document-title"
                maxLength={100}
                placeholder={t('editor.default_document_title')}
                onChange={onChange}
                onBlur={updateTitle}
                value={title}
              />
            </Tooltip>
          )}
          <div className="document-status">
            {isLoading ? (
              <Skeleton variant="rounded" width={40} data-testid="skeleton-status" />
            ) : (
              <Paragraph size="sm">{documentData?.status || DocumentStatus.Draft}</Paragraph>
            )}
            {isLoading ? (
              <Skeleton variant="text" width={50} height={32} data-testid="skeleton-price" />
            ) : (
              <>
                $<PriceInput value={documentPrice} onChange={setDocumentPrice} onBlur={updatePrice} />
              </>
            )}
            <Paragraph size="sm" className="timestamp-ml-xs">
              <SaveStatusText />
            </Paragraph>
          </div>
        </Col>
        <Col className="editor-menu-container__column--right">
          {isLoading ? (
            <Skeleton variant="rectangular" width={300} height={40} />
          ) : (
            <div className="buttons-container">
              <Button
                icon={<Icon name="IcoPreview" title={t('preview')} />}
                variant="neutral"
                type="default"
                className="preview-button"
                onClick={handlePreviewRedirect}
              >
                {t('preview')}
              </Button>
              <Button
                icon={<Icon name="IcoSend" title={t('share_document')} />}
                variant="positive"
                type="primary"
                className="send-button"
                onClick={openShareModal}
              >
                {t('share_document')}
              </Button>
              <DocumentShareModal
                open={isVisible}
                onCancel={closeShareModal}
                title={<Header title={t('share.title')} description={documentTitleWithCompanyName} />}
                destroyOnClose
              >
                <DocumentShare documentId={documentId} onSuccess={closeShareModal} />
              </DocumentShareModal>
            </div>
          )}
          <HelpButton />
          <ProfileActions />
        </Col>
      </Row>
      <EditorToolbar />
    </Layout.Header>
  );
}
